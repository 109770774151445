<template>
  <v-img
    height="75"
    width="191"
    contain
    :src="isLocaleFrench"
    :class="{'logo-img-fr': isLocaleFrench, 'logo-img-en': !isLocaleFrench }"
    @click="clickHandler"
  />
</template>

<script>
  export default {
    props: {
      onClick: {
        type: Function,
        default: () => {},
      }
    },
    computed: {
      isLocaleFrench () {
        return this.$t('images.logoPath')
      },
    },
    methods: {
      clickHandler () {
        this.onClick()
      }
    }
  }
</script>
