export const normalizeToEnglish = string =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(
      /([ąćęłńóśźżĄĆĘŁŃÓŚŹŻàâäáãåāæçèéêëíìîïıñóôõöøœßùúûüūÿýæœÁÂÄÀÃÅĀÆÇÈÉÊËÍÌÎÏİÑÓÔÕÖØÙÚÛÜŪŸÝ])/g,
      // Contains most common characters from another languages. Add more if needed.
      (_match, char) =>
      ({
        ą: 'a',
        ć: 'c',
        ę: 'e',
        ł: 'l',
        ń: 'n',
        ó: 'o',
        ś: 's',
        ź: 'z',
        ż: 'z',
        Ą: 'A',
        Ć: 'C',
        Ę: 'E',
        Ł: 'L',
        Ń: 'N',
        Ó: 'O',
        Ś: 'S',
        Ź: 'Z',
        Ż: 'Z',
        à: 'a',
        â: 'a',
        ä: 'a',
        á: 'a',
        ã: 'a',
        å: 'a',
        ā: 'a',
        æ: 'ae',
        ç: 'c',
        è: 'e',
        é: 'e',
        ê: 'e',
        ë: 'e',
        í: 'i',
        ì: 'i',
        î: 'i',
        ï: 'i',
        ı: 'i',
        ñ: 'n',
        ô: 'o',
        õ: 'o',
        ö: 'o',
        ø: 'o',
        œ: 'oe',
        ß: 'ss',
        ù: 'u',
        ú: 'u',
        û: 'u',
        ü: 'u',
        ū: 'u',
        ÿ: 'y',
        ý: 'y',
        Á: 'A',
        Â: 'A',
        Ä: 'A',
        À: 'A',
        Ã: 'A',
        Å: 'A',
        Ā: 'A',
        Æ: 'AE',
        Ç: 'C',
        È: 'E',
        É: 'E',
        Ê: 'E',
        Ë: 'E',
        Í: 'I',
        Ì: 'I',
        Î: 'I',
        Ï: 'I',
        İ: 'I',
        Ñ: 'N',
        Ô: 'O',
        Õ: 'O',
        Ö: 'O',
        Ø: 'O',
        Ù: 'U',
        Ú: 'U',
        Û: 'U',
        Ü: 'U',
        Ū: 'U',
        Ÿ: 'Y',
        Ý: 'Y',
      }[char])
    )
export const toCamelCase = str => {
  const camelCase = str
    // Split the string into words
    .split(' ')
    // Map each word to a new version
    .map((word, index) => {
      // If it's the first word, make it lowercase
      if (index === 0) {
        return word.toLowerCase()
      }
      // Capitalize the first letter of each subsequent word, and make the rest of the word lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    // Join all the words back into a single string
    .join('')
  return camelCase
}
